<!--
 * @Descripttion:
 * @version:
 * @Author: wangxiaole
 * @Date: 2020-02-19 14:00:54
 * @LastEditors:  
 * @LastEditTime: 2024-10-09 11:55:35
 -->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
  },
};
</script>
<style lang="less" >
#avocado-module-front-end-hq-system-config{
  height: calc(100vh - 110px);
    width: calc(100vw - 190px);
    overflow-y: auto;
    position: relative;
    margin: -10px -15px;
}
.el-message-box__status{
  top: 48%;
}
</style>
